<template>
  <Layout
    @click-left="back"
    @click-right="openPopup"
    :title="$route.query.nickname"
  >
    <template #right>
      <van-icon name="filter-o" size="20px" />
    </template>
    <div class="user_list_wrap">
      <!-- <div class="back_warp">
        <van-icon
          name="chat-o"
          :badge="isHaveNew"
          v-if="isHaveNew > 0"
          @click="reload"
          size="30px"
        />
      </div> -->
      <van-popup
        v-model="popupShow"
        position="right"
        :style="{ width: '70%', height: '100%' }"
      >
        <div class="user_list_tools">
          <div class="label">城市:</div>
          <el-cascader
            v-if="isShowCity"
            :show-all-levels="false"
            style="width: 100%"
            clearable
            v-model="cityOptions"
            :props="cityProps"
          >
          </el-cascader>
          <div style="color: #aaa; font-size: 14px">当前默认选择的城市：</div>
          <div style="color: #aaa; font-size: 14px">{{ getCurrentCity() }}</div>
          <!-- <el-select
          v-model="city"
          placeholder="城市"
          filterable
          style="width: 100%"
        >
          <el-option label="全部" value=" "></el-option>
          <el-option
            v-for="item in cityOptions"
            :key="item.city_code"
            :label="item.name"
            :value="item.city_code"
          >
          </el-option>
        </el-select> -->
          <div class="label">车型:</div>
          <el-select
            v-model="series"
            multiple
            filterable
            placeholder="车型"
            style="width: 100%"
          >
            <el-option
              v-for="item in seriesOptions"
              :key="item.seriesId"
              :label="item.seriesName"
              :value="item.seriesId"
            >
            </el-option>
          </el-select>
          <div class="label">互动:</div>
          <el-select
            v-model="interaction"
            placeholder="互动"
            style="width: 100%"
          >
            <el-option
              v-for="item in dayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="button-div">
            <van-button
              type="danger"
              plain
              style="margin-right: 20px"
              size="small"
              @click="resetClick"
              >重置</van-button
            >
            <van-button type="info" size="small" @click="sureClick"
              >确定</van-button
            >
          </div>
        </div>
      </van-popup>

      <div class="user_list_content" id="scrollDiv">
        <div class="statistics_wrap">
          <div class="statistics_content">
            <h3>最近场次统计</h3>
            <van-row>
              <van-col :span="8">
                <b>{{ fansInfo.fans || 0 }}</b>
                <span>全国</span>
              </van-col>
              <van-col :span="8">
                <b>{{ fansInfo.province || 0 }}</b>
                <span>同省</span>
              </van-col>
              <van-col :span="8">
                <b>{{ fansInfo.city || 0 }}</b>
                <span>同城</span>
              </van-col>
            </van-row>
            <p>最近更新时间 {{ fansInfo.last_live_time || "--" }}</p>
          </div>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="user_item-wrap"
            v-for="(item, index) in list"
            :key="index"
            :id="'id' + item.id"
            :title="item.nickname"
            @click="routerToDetail(item)"
          >
            <div class="item_box">
              <!-- 头像 -->
              <div class="avatar_box">
                <i>
                  <img :src="item.avatar" alt="" />
                </i>
                <h4>{{ item.nickname || "--" }}</h4>
                <p>{{ item.series_names || "" }} <b>{{item.visit_batch | formatDate2}}</b></p>
              </div>
              <!-- 城市 -->
              <div class="city_box">
                <h4>{{ item.last_enter_time | formatDate }}</h4>
                <p>{{ item.province_name }}-{{ item.city_name }}</p>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

  </Layout>
</template>

<script>
import commonApi from "@/api/common.js";
import eventBus from "@/utils/goBackEntity.js";
import Cookies from "js-cookie";
import Layout from "../layout";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    Layout,
  },
  filters: {
    formatDate(str = "") {
      let dateObj = moment(str);
      if (dateObj._isValid) {
        let y = moment().year();
        if (y < dateObj.year()) {
          return dateObj.format("YYYY-MM-DD HH:mm");
        } else {
          return dateObj.format("MM-DD HH:mm");
        }
      } else {
        return "--";
      }
    },
    formatDate2(str = "") {
      let dateObj = moment(str);
      if (dateObj._isValid) {
        return dateObj.format("MM-DD");
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      popupShow: false,
      cityProps: {
        lazy: true,
        multiple: true,
        value: "all",
        label: "name",
        leaf: "leaf",
        async lazyLoad(node, resolve) {
          const { level } = node;
          if (level == 2) {
            resolve();
            return false;
          }
          commonApi
            .getBuyCarListProps({
              parent_id: node.level == 0 ? 0 : node.data.id,
              level: level == 0 ? 1 : 2,
            })
            .then(({ data }) => {
              let newdata = data.data
              if (level == 1) {
                newdata.map((item) => {
                  item.leaf = level >= 1;
                  item.all = item.name + "," + item.id;
                  return item;
                });
              }
              resolve(newdata);
              this.cityOptions = newdata;
            });
        },
      },
      cityOptions: [],
      seriesOptions: [],
      dayOptions: [
        {
          value: "1",
          label: "近1天",
        },
        {
          value: "7",
          label: "近7天",
        },
        {
          value: "15",
          label: "近15天",
        },
        {
          value: "30",
          label: "近30天",
        },
        // {
        //   value: "60",
        //   label: "近60天",
        // },
        // {
        //   value: "90",
        //   label: "近90天",
        // },
      ],
      city: "", // 城市
      series: Cookies.get("series_ids")
        ? Cookies.get("series_ids").split("/")
        : [], // 车型
      interaction: Cookies.get("interaction") || "1", // 互动
      pageSize: 10,
      pageNo: 0,
      author_id: "", // 标记是否改刷新
      isShowCity: true,
      // isHaveNew: 0,
      fansInfo: { province: 0, city: 0, fans: 0, last_live_time: "" },
    };
  },
  computed: {
    ...mapState(["nickName"]),
  },
  created() {
    // keep alive 只触发一次
    this.initData();
    //根据key名获取传递回来的参数，data就是map中对应的key'id'的值
    eventBus.$on(
      "id",
      function (data) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$el.querySelector(`#id${data}`).scrollIntoView({
              behavior: "smooth",
            });
          }, 100);
        });
      }.bind(this)
    );
  },

  activated() {
    if (this.$route.query.author_id === this.author_id) {
      // 缓存
    } else {
      // 清除缓存
      this.list = [];
      this.pageNo = 0;
      this.initData();
      this.search(true);
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  mounted() {},
  methods: {
    initData() {
      this.author_id = this.$route.query.author_id;
      commonApi
        .getSeriesList({
          author_id: this.author_id,
          origin: this.$route.query.type == 1 ? "douyin" : "kuaishou",
        })
        .then(({ data }) => {
          let newArr = []
          for (let key in data.data) {
            newArr.push({
              seriesName: data.data[key],
              seriesId: key
            })
          }
          this.seriesOptions = newArr || [];
        });
      // commonApi
      //   .getIsHaveNew({
      //     author_id: this.author_id,
      //     origin: this.$route.query.type,
      //   })
      //   .then(({ data }) => {
      //     this.isHaveNew = data;
      //   });
      commonApi
        .getFansInfo({
          origin: this.$route.query.type,
          last_live_time: this.$route.query.public_time,
          author_id: this.$route.query.author_id,
        })
        .then(({ data }) => {
          console.log(1,data)
          let obj = data.data || {};
          let finish_time = this.$route.query.finish_time
          if(finish_time && finish_time !== 'null' && finish_time !== 'undefined') {
            obj.last_live_time = finish_time
          } else {
            obj.last_live_time = this.$route.query.public_time
          }
          this.fansInfo = obj;
        });
    },
    reload() {
      this.$router.go(0);
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.search();
    },
    search(isFirst) {
      if (isFirst) {
        this.scrollTop();
        this.pageNo = 0;
        this.list = [];
        this.finished = false;
      }
      this.pageNo += 1;
      let params = {
        city_code: this.getCurrentCityCode(),
        days: this.interaction,
        series_ids: this.series.join(","),
        page_size: this.pageSize,
        current: this.pageNo,
        author_id: this.$route.query.author_id,
        origin: this.$route.query.type == 1 ? "douyin" : "kuaishou",
      };
      // var this = this
      commonApi.getUserList(params).then(({ data }) => {
        this.loading = false;
        if (data.list.length == 0) {
          this.finished = true;
          return false;
        }
        this.list = this.list.concat(data.list);
      });
    },
    // 返回顶部
    scrollTop() {
      document.body.querySelector("#scrollDiv").scrollTo({
        top: 0,
      });
    },
    getCurrentCity() {
      if (!Cookies.get("city")) {
        return "暂无";
      }
      let arr = [];
      decodeURI(Cookies.get("city"))
        .split("/")
        .forEach((item) => {
          arr.push(item.split(",")[0]);
        });
      return arr.join(",");
    },
    getCurrentCityCode() {
      if (!Cookies.get("city")) {
        return "";
      }
      let arr = [];
      decodeURI(Cookies.get("city"))
        .split("/")
        .forEach((item) => {
          arr.push(item.split(",")[1]);
        });
      return arr.join(",");
    },
    routerToDetail(item) {
      let  addition = {};
      if (item.city_code) {
        addition = {
          province_name: item.province_name,
          province_code: item.province_code,
          city_name: item.city_name,
          city_code: item.city_code,
        };
      }
      if(item.visit_batch) {
        addition.visit_batch = item.visit_batch
      }

      this.$router.push({
        path: "/user-detail",
        query: {
          author_id: item.author_id,
          fans_id: item.fans_id,
          series_ids: item.series_ids,
          type: this.$route.query.type == 1 ? "douyin" : "kuaishou",
          code: item.code || item.fans_id,
          // city: `${item.province_name || "--"} -- ${item.city_name || "--"}`,
          id: item.id,
          nickname: item.nickname,
          ...addition,
        },
      });
    },
    back() {
      this.$router.push(`/home?userId=${this.$route.query.userId}`);
    },
    openPopup() {
      this.popupShow = true;
    },
    sureClick() {
      // 存搜索条件进入cookie
      let city_cookie = [];
      this.cityOptions.forEach((item) => {
        city_cookie.push(item[1]);
      });
      if (city_cookie.length > 0) {
        Cookies.set("city", city_cookie.join("/"));
      }
      Cookies.set("series_ids", this.series.join("/"));
      Cookies.set("interaction", this.interaction);

      this.search(true);
      this.popupShow = false;
    },
    resetClick() {
      // 清除cookie搜索条件
      Cookies.remove("city");
      Cookies.remove("series_ids");
      Cookies.remove("interaction");
      this.interaction = "1";
      this.series = [];
      this.cityOptions = [];
    },
  },
};
</script>

<style lang="less" scoped>
.back_warp {
  // padding-top: 6px;
  // padding-left: 20px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: flex-start;
  position: fixed;
  bottom: 100px;
  right: 30px;
}
.user_list_wrap {
  position: relative;
  background: #fff;
  .user_list_tools {
    padding: 5px 20px 10px;
    height: 100%;
    background: #fff;
    .button-div {
      margin-top: 20px;
      text-align: right;
    }
    .label {
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
    }
  }
  .margin-bottom {
    margin-bottom: 12px;
  }
  .user_list_content {
    padding-top: 0px;
    overflow-y: scroll;
    height: calc(100vh - 50px);
  }
}
.popup-text {
  color: #5579e2;
  font-size: 16px;
  margin-left: 20px;
}
.user_item-wrap {
  padding: 0 20px;
  > .item_box {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #edeff4;
    > .avatar_box {
      width: 60%;
      position: relative;
      padding-left: 56px;
      > i {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 5px;
        border: 1px solid #ddd;
        overflow: hidden;
      }
      > h4 {
        width: 100%;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      > p {
        font-size: 12px;
        font-weight: 500;
        color: #5579e2;
        > b {
          color: #ee0a24;
          font-weight: 400;
        }
      }
    }
    > .city_box {
      padding-top: 2px;
      > h4 {
        font-size: 12px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: right;
        margin-bottom: 10px;
      }
      > p {
        font-size: 12px;
        font-weight: 500;
        color: #9b9b9b;
        text-align: right;
      }
    }
  }
}

.statistics_wrap {
  padding: 10px;
  .statistics_content {
    border: 1px solid #ddd;
    padding: 5px 0;
    > p {
      padding-top: 10px;
      padding-right: 20px;
      color: #aaa;
      font-size: 10px;
      text-align: right;
    }
    > h3 {
      color: #aaa;
      font-size: 10px;
      margin-bottom: 5px;
      text-align: center;
    }
    b {
      display: block;
      margin-bottom: 5px;
      text-align: center;
    }
    span {
      display: block;
      text-align: center;
      color: #aaa;
      font-size: 10px;
    }
  }
}
</style>